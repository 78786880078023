<template>
  <table-container :list="list">
    <el-table-column type="index" label="序号" align="center">
    </el-table-column>
    <el-table-column prop="ordersn" label="订单号" align="center">
    </el-table-column>
    <el-table-column prop="inner_orderno" label="内部单号" align="center">
    </el-table-column>
    <el-table-column label="产品" align="center">
      <el-table-column label="名称" align="center">
        <template slot-scope="scope">
          <div
            class="item"
            v-for="(item, index) of scope.row.items"
            :key="index"
          >
            {{ item.goods_name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="规格" align="center" width="200">
        <template slot-scope="scope">
          <div
            class="item"
            v-for="(item, index) of scope.row.items"
            :key="index"
          >
            {{ item.spec_name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="价格" align="center">
        <template slot-scope="scope">
          <div
            class="item"
            v-for="(item, index) of scope.row.items"
            :key="index"
          >
            {{ item.item_price }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="成本" align="center">
        <template slot-scope="scope">
          <div
            class="item"
            v-for="(item, index) of scope.row.items"
            :key="index"
          >
            {{ item.item_cost }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="数量" align="center">
        <template slot-scope="scope">
          <div
            class="item"
            v-for="(item, index) of scope.row.items"
            :key="index"
          >
            {{ item.count }}
          </div>
        </template>
      </el-table-column></el-table-column
    >
    <el-table-column prop="expense" label="快递" align="center">
      <el-table-column prop="express_name" label="名称" align="center">
      </el-table-column>
      <el-table-column prop="express_price" label="价格" align="center">
      </el-table-column>
      <el-table-column prop="express_cost" label="成本" align="center">
      </el-table-column>
    </el-table-column>
    <!-- <el-table-column prop="withdraw" label="配件" align="center">
      <el-table-column prop="recharge" label="名称" align="center">
      </el-table-column>
      <el-table-column prop="withdraw" label="价格" align="center">
      </el-table-column>
      <el-table-column prop="recharge" label="成本" align="center">
      </el-table-column>
      <el-table-column prop="withdraw" label="数量" align="center">
      </el-table-column
    ></el-table-column> -->
    <el-table-column label="合计" align="center">
      <el-table-column prop="order_price" label="总价格" align="center">
      </el-table-column>
      <el-table-column prop="order_cost" label="总成本" align="center">
      </el-table-column>
    </el-table-column>
  </table-container>
</template>
<script>
export default {
  name: "List",
  props: {
    list: Array,
  },
  methods: {
    //充值
    handleRecharge() {},
    //提现
    handleWithdraw() {},
  },
};
</script>
<style lang="less" scoped>
.item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0 10px;
  margin: 0 -10px;
  border-bottom: 1px solid #ebeef5;
  box-sizing: border-box;
  line-height: 20px;

  &.disable {
    background-color: #e7e7e7;
  }
  &:last-child {
    border: none;
  }
  .iconfont {
    margin-left: 5px;
  }
}
</style>