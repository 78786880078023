<template>
  <div class="finance-container">
    <finance-nav @change="getAgentOrders"></finance-nav>
    <list :list="list"></list>
    <pagination
      :page="page"
      :pageSize="pageSize"
      :total="total"
      @sizeChange="handlePageSizeChange"
      @pageChange="handlePageChange"
    >
    </pagination>
  </div>
</template>
<script>
import List from "./components/List";
import FinanceNav from "./components/Nav";
export default {
  name: "TodayExpense",
  components: {
    FinanceNav,
    List,
  },
  data() {
    return {
      page: 1,
      pageSize: 10,
      total: 10,
      list: [
        {
          name: "莫名",
          recharge: 1000,
          expense: 200,
          withdraw: 0,
          balance: 800,
        },
        {
          name: "建平",
          recharge: 2000,
          expense: 200,
          withdraw: 0,
          balance: 1800,
        },
      ],
    };
  },
  created() {},
  methods: {
    //获取代理订单信息
    async getAgentOrders(data) {
      data.page = this.page;
      data.pageSize = this.pageSize;
      const res = await this.$store.dispatch("getAgentOrders", data);
      this.list = res.orders;
      this.total = res.total;
    },
    //切换页面展示数量
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getAgentList();
    },
    //切换页数
    handlePageChange(page) {
      this.page = page;
      this.getAgentList();
    },
  },
};
</script>
<style lang="less" scoped>
</style>