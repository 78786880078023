<template>
  <el-row :gutter="20" style="margin-top: 10px; flex: 1">
    <el-col :span="3">
      <el-select v-model="typeId" placeholder="选择店铺类型">
        <el-option
          v-for="item in typeList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-col>
    <el-col :span="3">
      <el-select v-model="agentId" placeholder="选择代理">
        <el-option
          v-for="item in agentList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-col>
    <el-col :span="7">
      <el-date-picker
        v-model="date"
        type="daterange"
        align="center"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="timestamp"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
    </el-col>
  </el-row>
</template>
<script>
import { dateShortcuts, toDaytime } from "@/util/shortcuts";
export default {
  name: "Nav",
  data() {
    return {
      typeId: "",
      typeList: [],
      agentId: "",
      agentList: [],
      date: toDaytime,
      startTime: new Date(new Date().toLocaleDateString()).getTime() / 1000,
      endTime:
        (new Date(new Date().toLocaleDateString()).getTime() +
          3600 * 1000 * 24) /
        1000,
      pickerOptions: {
        shortcuts: dateShortcuts,
      },
    };
  },
  computed: {
    paramChange() {
      return {
        date: this.date,
        typeId: this.typeId,
        agentId: this.agentId,
        recordId: this.recordId,
      };
    },
  },
  watch: {
    typeId(e) {
      this.getAgentList();
    },
    date(e) {
      if (e) {
        this.startTime = Math.floor(e[0] / 1000);
        this.endTime = Math.floor(e[1] / 1000);
      }
    },
    paramChange() {
      this.$tool.debounce(100).then(() => {
        this.handleChange();
      });
    },
  },
  created() {
    this.getAgentTypeList();
  },
  methods: {
    //获取代理类型列表
    async getAgentTypeList() {
      const res = await this.$store.dispatch("getAgentTypeList");
      this.typeList = res.items;
      this.typeId = this.typeList[0].id;
    },
    //获取代理列表
    async getAgentList() {
      const res = await this.$store.dispatch("getAgentList", {
        agent_type_id: this.typeId,
      });
      this.agentList = res.items;
      this.agentId = this.agentList[0].id;
    },
    //选择
    handleChange() {
      this.$emit("change", {
        agent_id: this.agentId,
        startTime: this.startTime,
        endTime: this.endTime,
      });
    },
  },
};
</script>
<style lang="less" scoped>
</style>